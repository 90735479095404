import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import FormSignature from '../components/FormSignature';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import SEO from '../components/SEO';

export default function render({data}) {
  return (
    <Layout>
      <SEO title={data.site.siteMetadata.title} />

      <Hero
        background={data.hero.childImageSharp.fluid}
        title={data.site.siteMetadata.title}
        description={data.site.siteMetadata.description}
        channel={data.site.siteMetadata.channel}
      />

      <main className="main">
        <div className="wrap">
          <p>A crise internacional têm, de maneira sistemática, se agravado e arrochado a vida do trabalhador do campo e da cidade. Junto dela, um ascenso do conservadorismo ao redor do mundo busca consumir os pequenos avanços conquistados pelos movimentos sociais na democracia burguesa.</p>

          <p>No Brasil, não é diferente, a chegada de Jair Messias Bolsonaro à Presidência da República simboliza um retrocesso na política. Um governo cujo objetivo perpassa pela venda do patrimônio nacional, pela perseguição às minorias políticas e pela tentativa de pôr fim à arte e a ciência. É um tripé, cuja sustentação é composta de ultraliberalismo, conservadorismo e obscurantismo.</p>

          <p>As declarações arbitrárias do Presidente da República caracterizam um desejo, mesmo que inconsciente, de fechamento do regime. Ao dizer que sabe informações, desconhecidas por todos, à respeito da morte de um estudante da UFF (Universidade Federal Fluminense), na ditadura militar; ao baixar uma portaria com a finalidade de extraditar, compulsoriamente, estrangeiros “que apresentem risco” ao governo; ao dizer que o jornalista Glenn Greenwald “poderia pegar alguns meses de cana” por dar publicidade à corrupção do Juiz Sérgio Moro e do Procurador Deltan Dallagnol, o governo mostra suas garras autoritárias.</p>

          <p>Na cadeira de Ministro da Educação está sentado o inimigo do povo, Abraham Weintraub, responsável por organizar a destruição da universidade pública brasileira, projeto esse que já vem sendo executado desde a sua posse com os cortes no orçamento das universidades e institutos federais. Agora, mais recentemente, apresenta um pacote de maldades, denominado “Future-se”, que consiste na venda da universidade pública para setores da indústria, colocando o ensino e a pesquisa a serviço do baronato, e não do povo brasileiro.</p>

          <p>Isso implicará, diretamente, na produção de ciência psicológica, que ficará submetida aos financiamentos das empresas e do setor privado, principalmente da indústria farmacêutica, com a perspectiva de patologizar e medicalizar a sociedade.</p>

          <p>A desregulamentação do ensino privado possibilita que, dentro das determinações do MEC (Ministério da Educação), as Universidades Particulares adotem, cada vez mais, disciplinas no regime EAD (Ensino À Distância), resultando na demissão de professores e na precarização do ensino em psicologia.</p>

          <p>Jair Bolsonaro declarou a psicologia brasileira como sua inimiga, ao atacar a reforma psiquiátrica, abrindo leitos de internação; ao aumentar o repasse para as comunidades terapêuticas, que não são regulamentadas e fiscalizadas; ao reduzir verba dos Centro de Atenção Psicossocial (CAPS) e do Sistema Único Assistência Social (SUAS); ao retirar, de maneira arbitrária, o Conselho Federal de Psicologia (CFP) do Conselho Nacional de Política sobre Drogas (CONAD).</p>

          <p>A crise, para além dos prejuízos econômicos e sociais, traz, consigo, o adoecimento mental da sociedade. Em conjunto com o desmonte das políticas públicas de saúde mental, issoresultará no retorno da era manicomial, uma lógica racista e higienista de controle da sociedade, como ocorreu no gravíssimo caso do manicômio de Barbacena, Minas Gerais, denominado também como “holocausto brasileiro”.</p>

          <p>Por outro lado, observamos os movimentos estudantis, de mulheres, de negras e negros e LGBT’s como linha de frente na resistência ao governo. Os dias 15 e 30 de março demonstraram que o movimento estudantil é a trincheira capaz de incendiar o país, na defesa dos direitos do povo.</p>

          <p>A União Nacional dos Estudantes (UNE) organizou o maior congresso de sua história, na capital do Brasil, para decidir os rumos das lutas estudantis pelo país.</p>

          <p>Porém, mesmo diante desse cenário, a Coordenação Nacional dos Estudantes de Psicologia - CONEP, organização que tem como principal objetivo agremiar os Centros e Diretórios Acadêmicos, de maneira lamentável, não têm cumprido com a sua tarefa. A CONEP tem ficado aquém daquilo que poderia mobilizar, em um momento de ataque à graduação, à ciência e à profissão.</p>

          <p>Nas universidades, públicas e particulares, os estudantes sequer conhecem a entidade e tampouco sabem da sua importância no cenário nacional, sobretudo por ser reconhecida pelos Conselhos Regionais e Federal, além das outras vinte associações que organizam os profissionais da psicologia.</p>

          <p>A CONEP precisa voltar a ser perigosa, como foi no passado, para organizar os estudantes para enfrentar o desmonte da educação e o ataque à psicologia.</p>

          <p>É preciso convocar, imediatamente, um Encontro Nacional de Estudantes de Psicologia (ENEP), para reorganizar os graduandos do Brasil. Precisamos de uma direção clara, democrática e com um estrutura que garanta a distribuição de função entre seus membros, eleita pela base da nossa categoria.</p>

          <p>Precisa-se pôr fim à burocratização e ao aparelhamento da entidade, uma reviravolta pela base, para garantir o direito ao futuro!</p>

          <p><strong>Entidades que assinam o manifesto:</strong></p>

          <p>CAPSI Nise da Silveira  - USF - SP<br />
          CAPSIU Marielle Franco - PA<br />
          CAPSI UNIFOR - CE<br />
          CAPSI Ana Mercês Bahia Bock - FMU - SP<br />
          CAPSI Mariantonia Chippari - Metodista - SP<br />
          CAPSI Carolina Bori - UNIFAMAZ - PA<br />
          CAPSI Carlos Bernardi - Estácio JU - RJ<br />
          CAPsi UFMG - MG<br />
          CAPSI CECÍLIA COIMBRA - UFF - RJ<br />
          CAPSI Nise da Silveira - PUC - RJ<br />
          CAPSI São Judas - SP<br />
          CAEPSI - Mackenzie São Paulo - SP<br />
          D.A Maria de Fátima Lobo Boschi - PUC - MG<br />
          Pró-CAPSI - Universidade da Amazônia (UNAMA) - PA<br />
          CAPSI - UNIVAP - SP<br />
          CAPSI Maria Solange Felix Pereira - UCDB - MS<br />
          CAPSI UFMA - MA<br />
          CAPSI Nise da Silveira - UFPA - PA</p>
        </div>
      </main>

      <div id="sign">
        <FormSignature
          channel={data.site.siteMetadata.channel}
          title={data.site.siteMetadata.title}
          url={data.site.siteMetadata.url}
        />
      </div>
    </Layout>
  );
}

render.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        channel
        url
      }
    }

    hero: file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
