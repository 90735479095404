import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';

import {count} from '../evag';

import styles from './CountSignatures.module.scss';

const delay = 50;
const parts = 20;

const calculateGoal = (number) => {
  if (number < 100) {
    return 100;
  }

  for (let e = 2; ; e++) {
    if (number < Math.pow(10, e)) {
      const p = Math.pow(10, e - 1);
      return Math.floor((number + p) / p) * p;
    }
  }
};

const numberFormat = (number) => (
  new Intl.NumberFormat('pt-BR').format(number)
);

class CountSignatures extends React.Component {
  constructor(props) {
    super(props);

    this.setCount = this.setCount.bind(this);

    this.state = {
      count: 0,
      error: false,
      loading: true,
      goal: 100,
    };
  }

  setCount(number) {
    const goal = calculateGoal(number);

    this.setState({
      goal: goal,
    });

    for (let i = 0; i < parts; i++) {
      window.setTimeout(() => {
        this.setState({
          count: Math.floor((i + 1) * number / parts),
        });
      }, (i + 1) * delay);
    }
  }

  componentDidMount() {
    count(this.props.channel)
      .then(res => {
        this.setCount(res.data.count);
      })
      .catch(err => {
        console.error(err);
        this.setState({
          error: true,
        });
      })
      .then(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    if (this.state.loading) {
      return (
        <Loader type="Ball-Triangle" color="#fff" height="40" width="40" />
      );
    }

    if (this.state.error) {
      return null;
    }

    const width = `${100.0 * this.state.count / this.state.goal}%`;

    const bar = (
      <div className={styles.bar}>
        <div className={styles.current} style={{width: width}} />
      </div>
    );

    const text = (
      <p>
        <strong>{numberFormat(this.state.count)} pessoas já assinaram.</strong>
        {` `}
        Ajude-nos a chegar a {numberFormat(this.state.goal)}!
      </p>
    );

    return this.props.bar
      ? (
        <div className={styles.container}>
          {bar}
          {text}
        </div>
      )
      : text;
  }
}

CountSignatures.defaultProps = {
  bar: true,
};

CountSignatures.propTypes = {
  bar: PropTypes.bool,
  channel: PropTypes.string.isRequired,
};

export default CountSignatures;
